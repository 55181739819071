import React from 'react'
import { graphql, Link } from 'gatsby'
import kebabCase from 'lodash/kebabCase'
import Layout from '../components/Layout'
import SEO from '../components/seo'

const TagsPageRoute = ({ data }) => {
  const allTags = data.allMdx.group

  return (
    <Layout>
      <SEO title="Tags" />
      <h1>Tags</h1>
      <ul>
        {allTags.map(tag => (
          <li key={tag.fieldValue}>
            <Link
              style={{ textDecoration: 'none' }}
              to={`/tags/${kebabCase(tag.fieldValue)}/`}
            >
              {tag.fieldValue} ({tag.totalCount})
            </Link>
          </li>
        ))}
      </ul>
    </Layout>
  )
}

export default TagsPageRoute

export const pageQuery = graphql`
  query TagsQuery {
    allMdx(limit: 2000, filter: { frontmatter: { draft: { ne: true } } }) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`
